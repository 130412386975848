.avis-format-section-case-cocher {
  position: relative;
  display: flex;
  width: 603px;
  height: 44px;
}

.avis-format-section-case-cocher.blueBorder {
  border: 2px solid #1479cc;
}

.avis-zonecoloree-information {
  width: 40px;
  border: solid #c5cad2;
  border-width: 1px 0 1px 1px;
}

.avis-zonecoloree-information {
  background: #d9e6f0;
}
.checkbox-round {
  width: 24px !important;
  height: 24px !important;
  background-color: whitesmoke;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-top: 10px;
}

.contenu-cac {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
