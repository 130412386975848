.btn {
  min-width: 112px;
  border-radius: 0;
  box-sizing: border-box;
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 14px 22px;
  justify-content: center;
  text-align: center;
  display: inline-flex;
  align-items: center;
  border: 2px solid transparent;
}

.btn:focus,
.btn:hover {
  cursor: pointer;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.btn + .btn {
  margin-left: 32px;
}

.btn-principal {
  box-shadow: 0 1px 4px rgba(34, 54, 84, 0.16);
  border: 2px solid #095797;
  background: #095797;
  color: #ffffff;
}

.btn-principal:hover,
.btn-principal:focus {
  background: #156bb2;
}

.btn-principal:hover {
  border: 2px solid #156bb2;
}

.btn-principal:focus {
  border-color: #223654;
  box-shadow: 0 2px 8px rgba(34, 54, 84, 0.16), 0 0 0 2px #4a98d9;
}

.btn-secondary {
  border-color: #095797;
  background: #ffffff;
  color: #095797;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: rgba(9, 87, 151, 0.16);
}

.btn-secondary:focus {
  border-color: #223654;
  box-shadow: 0 0 0 2px #4a98d9;
}

.btn-tertiary {
  border: none;
  background: none;
  color: #095797;
}

.btn-danger {
  box-shadow: 0 1px 4px rgba(34, 54, 84, 0.16);
  border: 2px solid #cb381f;
  background: #cb381f;
  color: #ffffff;
}

.btn-tertiary:hover {
  text-decoration: underline;
  background-color: rgba(197, 202, 210, 0.24);
}

.btn-tertiary:focus {
  border: 2px solid #223654;
  background-color: rgba(197, 202, 210, 0.16);
  box-shadow: 0 0 0 2px #4a98d9;
}

.btn .droite {
  margin-left: 8px;
}

.btn .gauche {
  margin-right: 8px;
}

.btn.inversee {
  padding: 8px 16px;
  min-width: 80px;
  vertical-align: top;
  font-weight: 600;
}

.btn-principal.inversee {
  background: #fff;
  color: #223654;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-color: #fff;
}

.btn-principal.inversee:hover,
.btn-principal.inversee:focus,
.btn-principal.inversee:active {
  background: #b8d7ef;
}

.btn-principal.inversee:hover {
  box-shadow: none;
  border-color: #b8d7ef;
}

.btn-principal.inversee:focus {
  border-color: #223654;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 0 0 2px #4a98d9;
}

.btn-principal-jaune.inversee {
  background: #223654;
  color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-color: #223654;
}

.btn-principal-jaune.inversee:hover,
.btn-principal-jaune.inversee:focus,
.btn-principal-jaune.inversee:active {
  background: #095797;
}

.btn-principal-jaune.inversee:hover {
  box-shadow: none;
  border-color: #095797;
}

.btn-principal-jaune.inversee:focus {
  border-color: #4a98d9;
  background: #223654;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 0 0 2px #223654;
}

.btn-secondary.inversee {
  background: none;
  border-color: #fff;
  color: #fff;
}

.btn-secondary.inversee:hover,
.btn-secondary.inversee:focus {
  background: rgba(218, 230, 240, 0.08);
}

.btn-secondary.inversee:focus {
  border-color: #223654;
  box-shadow: 0 0 0 2px #4a98d9;
}

.btn-tertiary.inversee {
  color: #fff;
}

.btn-tertiary.inversee:hover,
.btn-tertiary.inversee:focus {
  background-color: rgba(197, 202, 210, 0.16);
}

.btn-tertiary.inversee:focus {
  border-color: #223654;
  box-shadow: 0 0 0 2px #4a98d9;
}

.icon {
  padding-left: 9px;
  padding-right: 9px;
}

.btn .iconBoutonGauche {
  margin-right: 10px;
}

.btn .iconBouton {
  margin-left: 10px;
}
.btn .srOnly{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
@media screen and (max-width: 768px) {
  .btn + .btn {
    margin-left: 0;
    margin-top: 24px;
  }

  .btn {
    width: 100%;
  }

  .btn.inversee {
    min-width: 112px;
    width: auto;
    padding: 8px;
  }

  .btn.inversee + .btn.inversee {
    margin-top: 0;
    display: inline-block;
    margin-left: 16px;
  }
}
