.avis-format {
  position: relative;
  display: flex;
  width: 100%;
}

.avis-zonecoloree-succes,
.avis-zonecoloree-erreur,
.avis-zonecoloree-important,
.avis-zonecoloree-information,
.avis-zonecoloree-lampe {
  width: 40px;
  border: solid #c5cad2;
  border-width: 1px 0 1px 1px;
}

.avis-zonecoloree-succes {
  background: #bcda9a;
}

.avis-zonecoloree-erreur {
  background: #edbab1;
}

.avis-zonecoloree-important {
  background: #f8e69a;
}

.avis-zonecoloree-information {
  background: #d9e6f0;
}
.avis-zonecoloree-lampe {
  background: #f1f1f2;
}

.avis-pictogramme {
  width: 24px;
  height: 24px;
  left: 8px;
  top: 24px;
  right: 8px;
  margin: 24px 8px 0 8px;
}

.contenuAvis {
  border: solid #c5cad2;
  border-width: 1px 1px 1px 0;
  padding: 20px 16px 22px 32px;
  color: #223654;
  width: 100%;
}

.contenuAvis h1,
.contenuAvis h2,
.contenuAvis h3,
.contenuAvis h4,
.contenuAvis h5,
.contenuAvis h6 {
  font-family: "Open Sans", serif;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}

.contenuAvis a {
  color: #cb381f;
  font-size: 14px;
}

.contenuAvis a:hover {
  text-decoration: none;
}

.contenuAvis li.content a {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-decoration-line: underline;
  color: #cb381f !important;
  line-height: 19px;
}

.contenuAvis ul.list {
  padding-left: 0px;
}

.contenuAvis li.content a:visited {
  color: #cb381f !important;
}

.contenuAvis li.content a:hover {
  text-decoration: none;
}

.contenuAvis li.content {
  margin-top: 0;
  line-height: 5px;
}

.contenuAvis li.content::marker {
  font-size: 12px;
}

.contenuAvis .list-container {
  margin-top: 22px;
}

.contenuAvis p ~ .list-container {
  margin-top: 18px;
}

.contenuAvis p {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px !important;
  line-height: 20px;
}

.contenuAvis strong,
.contenuAvis b {
  font-size: 14px;
  line-height: 20px;
}

/******************************************************/

.avis-texte:last-child {
  margin-bottom: 22px;
}

.avis-texte-1-ligne {
  margin-left: 0;
  font-weight: normal;
}

.avis-texte + .list-container {
  margin-top: 18px;
}

@media only screen and (max-width: 768px) {
  .contenuAvis .list {
    width: auto;
  }
}
