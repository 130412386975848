@import url("https://fonts.googleapis.com/css2?family=Roboto");
.conteneur-pied-de-page {
  width: 100%;

  min-width: 280px;
}

.conteneur-pied-de-page li {
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  /* Bug bounty */
  color: #095797;
}
.premiere-section {
  background: #223654;
}

.lien-externe {
  margin-right: unset;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: top;
}

.premiere-section-conteneur {
  flex: auto;
  background: #223654;
  display: flex;
  margin: 0 auto;
  padding-bottom: 34px;
  width: 100%;
  max-width: 1152px;
  justify-content: space-between;
  box-sizing: border-box;
}

.sous-section-premiere-section {
  margin-top: 52px;
  word-break: break-word;
  display: inline-block;
  max-width: 853px;
  width: 60%;
}

.sous-section-premiere-section img {
  margin-left: 10px;
}

.sous-section-deuxieme-section {
  margin-top: 50px;
  word-break: break-word;
  display: inline-block;
  width: 40%;
}

.icone-section-deuxieme-section {
  margin-right: 16px;
  vertical-align: middle;
}

.ligne-info {
  flex: auto;
  display: flex;
  color: #ffffff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.ligne-info + .ligne-info {
  margin-top: 24px;
}

.infoDetail {
  line-height: 16px;
}

.sous-section-premiere-section a,
.sous-section-premiere-section .titre,
.sous-section-premiere-section .lien-pied-de-page,
.sous-section-premiere-section .lienCompose,
.sous-section-deuxieme-section a,
.sous-section-deuxieme-section .titre,
.sous-section-deuxieme-section .lien-pied-de-page {
  color: #ffffff;
}

.sous-section-premiere-section .titre {
  font-family: "Roboto", "Open Sans", "Work Sans", "Trebuchet MS", "Arial",
    sans-serif;
  margin-bottom: 26px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.conteneur-lien {
  flex-direction: row;
}

.sous-section-premiere-section .lien-pied-de-page {
  font-family: "Open Sans", "Work Sans", "Trebuchet MS", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.lien-pied-de-page {
  margin-top: 16px;
}
.copyright {
  margin-left: 50px;
}

.sous-section-deuxieme-section .titre {
  font-family: "Open Sans", "Work Sans", "Trebuchet MS", "Arial", sans-serif;
  margin-bottom: 28px;
  font-weight: 700;
  line-height: 24px;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0px;
  text-align: left;
}

/****************************/
/*  deuxième section        */
/****************************/
.deuxieme-section {
  font-family: "Open Sans", "Work Sans", "Trebuchet MS", "Arial", sans-serif;
  text-align: center;
  background: #ffffff;
  padding: 28px 10% 34px;
}

.li_marge_droit {
  margin-right: 48px;
}

.deuxieme-section a {
  color: #095797;
  font-size: 14px;
  font-family: "Open Sans", "Work Sans", "Trebuchet MS", "Arial", sans-serif;
}

.icone_lien_externe_couleur {
  margin-left: 6px;
  display: inline-block;
}

.pied_logo {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-pied-de-page {
  height: 35px;
  width: 117px;
  vertical-align: top;
}

.section-liens-deuxieme-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.conteneur-pied-de-page ul {
  list-style-type: none;
  padding-inline-start: initial;
}

.conteneur-pied-de-page a {
  text-decoration: none;
}

.conteneur-pied-de-page a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 1300px) {
  .premiere-section-conteneur {
    max-width: 100%;
    padding: 0 72px 34px;
  }
}

@media only screen and (max-width: 850px) {
  .premiere-section-conteneur {
    display: flex;
    flex-direction: column;
    padding: 52px 16px;
    margin: 0 auto;
  }

  .sous-section-premiere-section,
  .sous-section-deuxieme-section {
    width: unset;
  }

  .sous-section-premiere-section .titre {
    margin-bottom: 32px;
  }

  .sous-section-deuxieme-section {
    margin-top: 38px;
  }

  .sous-section-premiere-section li {
    margin-top: 16px;
  }

  .li_marge_droit {
    margin-right: unset;
    margin-bottom: 16px;
  }

  .sous-section-deuxieme-section img {
    margin-right: 15px;
  }

  .conteneur-lien {
    flex-direction: column;
    margin: auto;
  }
  .section-liens-deuxieme-section {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }
  .deuxieme-section {
    margin: unset;
    padding: 16px 16px 32px 16px;
    background: #ffffff;
    text-align: center;
  }
  .deuxieme-section li:last-child {
    margin-bottom: 0;
  }
  .pied_logo {
    margin-top: 32px;
  }

  .ligne-info + .ligne-info {
    margin-top: 16px;
  }
}
.conteneur-section-securite {
  font-family: "Open Sans", "Work Sans", "Trebuchet MS", "Arial", sans-serif;
  display: block;
  align-items: center;
  justify-content: center;
  max-width: 20rem;
  margin: 0 auto;
  text-align: left;
  padding-top: 42px;
  padding-bottom: 42px;
  line-height: 22px;
  font-size: 14px;
}

.conteneur-section-securite1 {
  display: flex;
  padding-top: 42px;
  padding-bottom: 10px;
}

.conteneur-section-securite2 {
  display: flex;
  padding-left: 70px;
}

.icone-Sag-securite {
  margin-right: 10px; /* Add a margin to the right of the icon */
}
