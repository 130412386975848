.texte-conteneur {
  text-align: start;
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", serif;
  font-size: 16px;
  color: #223654;
  margin-bottom: 32px;
  width: 100%;
}

.extra-large {
  max-width: 528px;
}

.large {
  max-width: 248px;
}

.moyen {
  max-width: 160px;
}

.petit {
  max-width: 64px;
}

.champs-label {
  margin-bottom: 4px;
  font-weight: bold;
  width: fit-content;
}

.champs-texte {
  border: 1px solid #808a9d;
  width: 100%;
  height: 40px;
  font-family: "Open Sans", serif !important;
  font-size: 16px !important;
  color: #223654 !important;
  padding: 0 8px;
  box-sizing: border-box;
}

.password {
  padding-right: 42px;
}

.champs-texte:focus {
  outline: 2px solid #89bce5;
  border: 2px solid #5b6476;
}

.input-container:focus-within i,
.champs-texte:hover + i,
.oeil:hover {
  visibility: visible;
}

.precision {
  max-width: 100%;
  font-size: 14px;
  margin-bottom: 4px;
  line-height: 21px;
}

.erreur-message {
  color: #cb381f;
  font-weight: 600;
  margin-top: 8px;
}

.warning {
  max-width: 528px;
  color: #cd7105;
  font-weight: 600;
  margin-top: 4px;
}

.erreur {
  border: 3px solid #cb381f !important;
}

.score-world {
  font-size: 14px;
  font-weight: bold;
  font-family: Roboto, sans-serif;
}

.input-container {
  position: relative;
  max-width: 528px;
}

.oeil {
  position: absolute;
  top: 6px;
  margin-left: 8px;
  visibility: hidden;
  cursor: pointer;
}

.oeil:focus {
  outline: none;
  border-color: #223654;
  background-color: rgba(197, 202, 210, 0.24);
  box-shadow: 0 0 0 2px #4a98d9;
}

.extra-large + .oeil {
  right: 8px;
}

.large + .oeil {
  left: 206px;
}

.champs-lien {
  color: #095797;
  margin-top: 4px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}

.suggestion-conteneur {
  margin-top: 11px;
  width: 60%;
}

.suggestion-label {
  font-weight: 600;
  color: #5f6368;
}

.suggestion-valeur {
  border: none !important;
  background: none !important;
  font-family: "Open Sans", serif;
  font-size: 16px;
  color: #095797;
  margin-left: 20px;
}

.suggestion-valeur:hover,
.suggestion-valeur:focus {
  text-decoration: underline;
}

.composantADroiteLibelle {
  margin-left: 16px;
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .input-container,
  .large,
  .moyen,
  .petit,
  .warning {
    max-width: 343px;
  }

  .large + .oeil {
    left: unset;
  }

  .oeil {
    right: 8px;
  }
}
